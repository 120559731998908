import React from "react";
import classnames from "classnames";
import Link from "next/link";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Space, SpaceStatus } from "@politiker/react-core";

interface SpaceCardProps {
  className?: string;
  tall: boolean;
  space: Space;
}

function getSpaceStatusLabel(status: SpaceStatus) {
  return (
    {
      ONGOING: "En cours",
      CLOSED: "Terminée",
      DRAFT: "Brouillon",
    }[status] || "Consultation"
  );
}

const CardThumbnail = styled.div`
  flex: 0 1;
  position: relative;
  z-index: 5;
  img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Status = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  background-color: #99999966;
  color: white;
  font-weight: 400;
  font-style: italic;
  padding: 0.2rem 0.4rem;
  font-size: 16px;
  z-index: 5;
`;
const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  padding: 0.5rem;
  h5 {
    flex: 1;
    font-size: 16px;
  }
`;
const CardHoverLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;
const Card = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  padding: 0;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;

  &.--tall {
    ${CardThumbnail} {
      flex: 1 0;
    }
    ${CardBody} {
      flex: 0 1;
      min-height: 50px;
    }
  }
`;
/*
  &__actions
    justify-self: flex-end
    text-align: right

  &__action
    font-size: 13px
    color: rgb(141,141,141)
    &--active
      color: rgb(52, 139, 157)
*/
export function SpaceCard(props: SpaceCardProps) {
  const { className, tall, space } = props;

  return (
    <Card
      className={classnames(className, {
        "space-card--tall": tall,
      })}
      role="button"
      aria-pressed="false"
      aria-labelledby={`space-card-title-${space.slug}`}
    >
      <Link href={`/${space.slug}/home`}>
        <CardHoverLink aria-label={`Aller sur l'espace ${space.name}`} />
      </Link>
      <CardThumbnail>
        <Status>{getSpaceStatusLabel(space.status)}</Status>
        <img src={space.cover_url} className="" />
      </CardThumbnail>
      <CardBody>
        <h5 id={`space-card-title-${space.slug}`}>{space.name}</h5>
      </CardBody>
    </Card>
  );
}

SpaceCard.propTypes = {
  className: PropTypes.string,
  tall: PropTypes.bool,
  space: PropTypes.object.isRequired,
};

interface SpaceListProps {
  className?: string;
}

const StyledList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  @media screen and (min-width: 800px) {
    flex-flow: row nowrap;
  }
  align-items: stretch;
  ${Card} {
    flex: 1 0;
    margin-right: 0.5rem;
    box-sizing: border-box;
  }
  ${CardThumbnail}, ${CardThumbnail} img {
    max-height: 136px;
  }
`;

const SpaceList: React.FC<SpaceListProps> = (props) => {
  const { className, children } = props;
  return <StyledList className={className}>{children}</StyledList>;
};

SpaceCard.List = SpaceList;
