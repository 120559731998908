import React from "react";
import styled from "styled-components";

export const Heading = styled.h1``;

const ParagraphVariants = {
  primary: styled.p`
    padding: 1.5rem 0;
    font-size: 17px;
    color: #28a8c6;
  `,
};

interface ParagraphProps {
  variant: keyof typeof ParagraphVariants;
  children: React.ReactChildren;
}

export function Paragraph(props: ParagraphProps) {
  const { variant, children } = props;
  const ParagraphVariant = ParagraphVariants[variant];

  return <ParagraphVariant>{children}</ParagraphVariant>;
}
