import { Helmet } from "react-helmet";
import { QueryClient } from "react-query";
import { dehydrate } from "react-query/hydration";
import { Container } from "reactstrap";
import styled from "styled-components";

import { useSpaceList } from "@politiker/react-core";

import { CoreApi } from "@/libs/api";

import { SpaceCard } from "../libs/common/SpaceCard";
import { Paragraph } from "../libs/common/Typography";

export async function getStaticProps() {
  const queryClient = new QueryClient();

  await queryClient.prefetchQuery(["spaces"], CoreApi.spaces.list);

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 60,
  };
}

const SpaceTiles = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  @media screen and (min-width: 800px) {
    flex-flow: row nowrap;
    align-items: stretch;
  }
`;

const ContainerTiles = styled.div`
  @media screen and (max-width: 799px) {
    padding: 0px;
  }
`;

export default function Home() {
  const spacesQuery = useSpaceList();
  return (
    <>
      <Helmet>
        <title>Accueil</title>
      </Helmet>
      <Container>
        <Paragraph variant="primary">
          Partager ses idées, trouver des solutions concrètes pour améliorer sa
          ville, son organisation, avancer sur des projets en prenant en compte
          l'expérience de chacun, prioriser des objectifs, lancer des sondages,
          partager des informations et géolocaliser des besoins, la parole est à
          vous.
        </Paragraph>
      </Container>
      <div
        className="container-fluid py-4"
        style={{ backgroundColor: "rgb(229,229,229)" }}
      >
        <Container>
          <SpaceTiles>
            <div
              style={{ maxWidth: 600 }}
              className="d-flex align-items-stretch"
            >
              <SpaceCard space={spacesQuery.data?.[0]} tall />
            </div>

            <ContainerTiles className="container-fluid">
              <SpaceCard.List>
                {spacesQuery.data?.slice(1, 4).map((space) => (
                  <SpaceCard key={space.id} space={space} />
                ))}
              </SpaceCard.List>
              <SpaceCard.List>
                {spacesQuery.data?.slice(4, 7).map((space) => (
                  <SpaceCard key={space.id} space={space} />
                ))}
              </SpaceCard.List>
            </ContainerTiles>
          </SpaceTiles>
          <p
            className="text-center"
            style={{ marginTop: "2rem", fontSize: 17 }}
          >
            <a href="#all-spaces" style={{ color: "#28A8C6" }}>
              Afficher tous les espaces de concertation
            </a>
          </p>
        </Container>
      </div>
      <HomeVideoSection />
      <HomeAllSpacesSection spacesQuery={spacesQuery} />
    </>
  );
}

const VideoFrame = styled.iframe`
  display: block;
  max-width: 100%;
  width: 800px;
  height: 480px;
  margin: 0 auto;
  max-height: 62vw;
`;
function HomeVideoSection() {
  return (
    <div className="container">
      <div className="row my-5 video">
        <div className="col">
          <h2
            style={{
              color: "black",
              textTransform: "none",
              fontWeight: 500,
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            Le dialogue et la collaboration : une idée qui rassemble
          </h2>
          <VideoFrame
            title="politiker video"
            width="800"
            height="480"
            src="https://www.youtube.com/embed/AnKlDepOFHA"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
}

const SpaceGrid = styled(SpaceCard.List)`
  justify-content: space-between;
  flex-flow: row wrap;
`;
const PhantomCard = styled.div`
  height: 0;
  flex: 1 0;
  margin-right: 0.5rem;
  min-width: 200px;
`;
function HomeAllSpacesSection({ spacesQuery }) {
  return (
    <>
      <hr id="all-spaces" style={{ visibility: "hidden" }} />

      <div
        className="container-fluid py-4"
        style={{ backgroundColor: "rgb(229,229,229)" }}
      >
        <div className="container">
          <h2
            className="text-center"
            style={{
              color: "black",
              textTransform: "none",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            Tous les espaces de concertation
          </h2>
          <p
            className="text-center"
            style={{
              fontSize: 15,
              color: "rgb(40,168,198)",
              margin: "1rem 2rem",
            }}
          >
            Devenez membre d&apos;un espace pour y contribuer : voter, partager
            des idées, répondre et proposer des sondages, réaliser un
            signalement.
            <br />
            La découverte des espaces, des idées, des actualités de chaque
            espace ggt accessible à tous.
          </p>
          <SpaceGrid>
            {spacesQuery.data?.map((space) => (
              <SpaceCard
                key={space.id}
                space={space}
                className="col col-sm-6 col-md-3"
                style={{ minWidth: 200 }}
              />
            ))}
            <PhantomCard />
            <PhantomCard />
            <PhantomCard />
            <PhantomCard />
            <PhantomCard />
          </SpaceGrid>
        </div>
      </div>
    </>
  );
}
